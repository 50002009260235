import imageCompression from 'browser-image-compression';

export const compressImage = async (file: any) => {
  const compressFile = await imageCompression(file, {
    maxSizeMB: 0.2,
    initialQuality: 0.95,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    fileType: 'image/webp',
  });

  const originalName = file.name;
  const nameWithoutExtension = originalName.substring(0, originalName.lastIndexOf('.'));
  const newFileName = `${nameWithoutExtension}.webp`; // Append .webp

  return new File([compressFile], newFileName, {
    type: compressFile.type,
  });
};
