import { useMutation } from 'react-query';
import { removePropertyImages, uploadPropertyImages } from '../../../services/properties';
import { compressImage } from '../../../utils/file';

export const useMutateUploadPropertyImage = (propertyId: number) => useMutation({
  mutationFn: async ({ file, sequencePosition }: { file: File, sequencePosition: number }) => {
    const formData = new FormData();
    const compressFile = await compressImage(file);
    formData.append('file', compressFile);
    formData.append('sequence_position', `${sequencePosition}`);
    return uploadPropertyImages(propertyId, formData);
  },
});

export const useMutateRemoveImage = (propertyId: number) => useMutation({
  mutationFn: (imageId: number) => removePropertyImages(propertyId, imageId),
});
